import _ from 'lodash'
import React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import * as ss from 'styled-system'

import theme from '../../config/theme'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { ExternalLink } from '../elements/Link'
import Body from '../elements/Body'
import pattern2 from '../pattern2.svg'
import patternLight from '../pattern2-light.svg'

import { setConfig, hot } from 'react-hot-loader'
setConfig({ pureSFC: true })

const getTheme = () => theme
const IndexPage = () => (
  <ThemeProvider theme={getTheme}>
    <>
      <SEO />
      <Layout>
        <ColoredBody {...theme.colors[1]} blendMode="multiply">
          <Title>Evan Schneider</Title>
          {/* <Subtitle>creative / designer / engineer</Subtitle> */}
          <Subtitle>creative / designer / engineer</Subtitle>
          <Caption>Building ideas from concept to execution.</Caption>
        </ColoredBody>

        <ColoredBody
          {...theme.colors[0]}
          blendMode="multiply"
          backgroundImage={patternLight}
          backgroundSize="60rem"
        >
          <Title>Hi, I'm Evan.</Title>
          <Paragraph>
            I've created products and matured ideas for over eight years.
          </Paragraph>
          <Paragraph>
            Throwing myself at a new idea is my favorite way to spend my time —
            that means my hands are always busy building that{' '}
            <i>something new</i> or learning how it's done. 🤓
          </Paragraph>
          <Paragraph>
            I believe that creating with others is one of the few great joys in
            life. And, if you feel that way too, I'd like to hear from you.
          </Paragraph>
        </ColoredBody>
        <ColoredBody
          {...theme.colors[2]}
          minHeight="20rem"
          blendMode="multiply"
        >
          <Subtitle>Find me here</Subtitle>
          <Flex>
            <Caption>
              <ExternalLink href="mailto:me@evanrs.com">Email</ExternalLink>
            </Caption>
            <Caption>
              <ExternalLink href="https://github.com/evanrs">
                Github
              </ExternalLink>
            </Caption>
            <Caption>
              <ExternalLink href="https://twitter.com/evanrs">
                Twitter
              </ExternalLink>
            </Caption>
            <Caption>
              <ExternalLink href="https://codepen.com/evanrs">
                Codepen
              </ExternalLink>
            </Caption>
            {/* <Caption>
              <ExternalLink href="https://www.npmjs.com/~evanrs">
                npm
              </ExternalLink>
            </Caption> */}
          </Flex>

          <div
            style={{
              marginTop: '2rem',
              padding:
                'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
            }}
          />
        </ColoredBody>
      </Layout>
    </>
  </ThemeProvider>
)

const Flex = styled.span`
  display: grid;
  grid-template-columns: repeat(${props => props.children.length}, max-content) 1fr;
  grid-column-gap: 2rem;
`

const ColoredBody = styled(Body)`
  ${({ bg, fg }) => `
    box-shadow: 0 -1rem 8rem 1rem ${bg} inset;
    background-color: ${bg};
    color: ${fg};
 `};

  background-position: 50% 50%;
  background-repeat: repeat;

  ${({ backgroundImage, backgroundSize, blendMode }) => css`
    background-image: url(${backgroundImage || pattern2});
    background-size: ${backgroundSize || '44rem'};
    background-blend-mode: ${blendMode || 'overlay'};
  `}
`

ColoredBody.defaultProps = {
  minHeight: '50%',
  py: ['2rem', '3rem', '4rem', '5rem'],
}

const Title = styled.h1`
  color: ${theme.accentColor};
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 900;
`

const Subtitle = styled.h2`
  font-size: 2.125rem;
  line-height: 2.5rem;
  /* text-transform: uppercase;
  text-transform: lowercase; */
  letter-spacing: -0.5px;
  word-spacing: 0.0625rem;
  font-weight: 200;
`

const Caption = styled.h3`
  font-size: 1.3125rem;
  line-height: 1.5rem;
  margin: 1.25rem 0 0;

  letter-spacing: -0.0625rem; /*1px*/
  letter-spacing: -0.046875rem; /*.75px*/
  letter-spacing: -0.03125rem; /*.5px*/
  font-weight: 400;

  letter-spacing: -0.046875rem;
  font-weight: 500;
`

const Paragraph = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;

  font-weight: 400;

  margin: 2rem 0;
  max-width: 40rem;

  white-space: pre-line;

  i {
    white-space: nowrap;
  }

  p + & {
    margin-top: -1rem !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export default hot(module)(IndexPage)
