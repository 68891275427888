const theme = require('./theme')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Evan Schneider - Portfolio', // Navigation and Site Title
  siteTitleAlt: 'evanrs', // Alternative Site title for SEO
  siteUrl: 'https://evanrs.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest
  siteDescription:
    'Evan Schneider has over 8 years of experience as an interface designer and software engineer.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@evanrs', // Twitter Username
  ogSiteName: 'evanrs', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  themeColor: theme.accent.bg,
  backgroundColor: theme.primary.bg,
}
