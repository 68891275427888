import React from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import * as ss from 'styled-system'

const widths = ['320px', '768px', '1024px'].map(str => `(min-width: ${str})`)
const createDevicePadding = (axis, [min, ...rest]) =>
  css`
    ${axis.map(dir => `padding-${dir}: ${min}`)};
    ${rest.map((size, i) => {
      const rule = widths[i]
      return (
        rule &&
        css`
          @media ${rule} {
            ${axis.map(dir => `padding-${dir}: ${size}`)};
          }
        `
      )
    })}
  `

const ViewportPadding = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  isolation: isolate;

  ${ss.space};
  ${ss.width};
  ${ss.height};
  ${ss.minWidth};
  ${ss.minHeight};
  ${ss.color};
  ${ss.bgColor};
  ${ss.flexDirection};
`

ViewportPadding.defaultProps = {
  flexDirection: 'column',
  px: ['2rem', '2rem', '3rem', '5rem'],
  minHeight: ['20rem', '24rem', '28rem'],
}

const ViewportBody = styled.div`
  margin: 0 auto;

  width: 100%;
  max-width: 60rem;
`

export const Body = ({ before, children, after, ...props }) => (
  <ViewportPadding {...props}>
    {before}
    <ViewportBody>{children}</ViewportBody>
    {after}
  </ViewportPadding>
)
export default Body
